const FormError = ({ errors }) => {
  var firstKey = Object.keys(errors)[0];
  return (
    <div className="row px-10">
      <div className="col-xl-12">
        {Object.keys(errors).length > 0 ? (
          <p className="invalid-feedback">
            {errors[firstKey].type === "required"
              ? "Required Information's need to be fill in both the Languages."
              : null}
          </p>
        ) : null}
        {errors?.root && (
          <p className="invalid-feedback">{errors.root.message}</p>
        )}
      </div>
    </div>
  );
};

export default FormError;
