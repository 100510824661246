export const getValueInLanguage = (data, langId) => {
  return data.find((d) => d.language_id === langId);
};

export const getLanguageId = (languages, langCode = "en") => {
  return languages.find((language) => language.code === langCode)?.id || 1;
};

export const generateRandomString = (length) => {
  /* const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; */
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

export const formatDeliveryPriceData = (data) => {
  const result = Object.values(
    data.reduce((acc, item) => {
      const key = item.state_id;

      if (!acc[key]) {
        acc[key] = {
          name: { label: item.name, value: item.state_id },
          deliveryPrices: [],
        };
      }

      acc[key].deliveryPrices.push(item.shipping_price.toString());

      return acc;
    }, {})
  );
  return result;
};

export const filterVariants = (old_data, new_data) => {
  const add = new_data.filter(
    (newItem) =>
      !old_data.some(
        (oldItem) =>
          oldItem.color_id === newItem.colorId &&
          oldItem.size_id === newItem.sizeId
      )
  );

  // Find elements in old_data that are not in new_data
  const deleteArr = old_data.filter(
    (oldItem) =>
      !new_data.some(
        (newItem) =>
          oldItem.color_id === newItem.colorId &&
          oldItem.size_id === newItem.sizeId
      )
  );
  return [add, deleteArr];
};

export const productSettingTooltipText = [
  "This is how much sales affect the overall ranking of a product in the 'Trending Products' list. In the scoring system, this percenatge of the final score for each product is based on its total sales during the specified time period (e.g., 1st Nov to 20th Nov). So, products that have sold more will score higher in this category.",
  "This shows how much the number of views (how often people visit a product's page) contributes to a product's ranking as a trending item.In the scoring system, this percentage of the final score for each product comes from the total number of views during the same period. A higher number of views increases a product's chance of being trending, but not as much as sales.",
  "This is the beginning date for the data collection period. From this date onward, the system starts tracking sales and views for each product.",
  "This is the last date the system will use to track sales and views for each product.",
  "This is the number of products the system will rank and show as the 'Trending Products.'",
];

export const OrderStatusObj = {
  order_placed: "Order Placed",
  order_confirmed: "Order Confirmed",
  dispatched: "Dispatched",
  shipped: "Shipped",
  in_transit: "In Transit",
  delivered: "Delivered",
  Cancelled: "Cancelled",
  refund: "Refund",
  cancel_request_received: "Cancel Request Received",
  accepted: "Cancel Request Accepted",
  rejected: "Cancel Request Rejected",
  return_request_received: "Return Request Received",
  return_accepted: "Return Accepted",
  return_rejected: "Return Rejected",
  pick_up_confirmed: "Pick Up Confirmed",
};

export const stockMovementType = {
  update_by_admin: "Update By Admin",
  new_order: "New Order",
  update_from_order_return: "Order Return",
};

export const ItemReturnStatusObj = {
  request_received: "Return Request Received",
  accepted: "Return Request Received",
  rejected: "Return Request Rejected",
  pickup_expected: "Item Pick up",
  refund: "Refund",
};
