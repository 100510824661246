import Login from "../pages/Login/Login";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";

import Dashboard from "../pages/Dashboard/Dashboard";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import Profile from "../components/Profile/Profile";
import AddLanguageSetting from "../components/LanguageSetting/Add";
import ViewAllLanguageSetting from "../components/LanguageSetting/ViewAll";
import ViewAllSettings from "../components/Settings/ViewAll";
import ViewPrefix from "../components/Settings/ViewPrefix";
import AddSetting from "../components/Settings/Add";
import EditSetting from "../components/Settings/Edit";
import ViewAllCms from "../components/Cms/ViewAll";
import ViewOneCms from "../components/Cms/NewViewOne";
import AddCms from "../components/Cms/NewAdd";
import EditCms from "../components/Cms/NewEdit";
import ViewAllEmailTemplate from "../components/EmailTemplate/ViewAll";
import AddEmailTemplate from "../components/EmailTemplate/Add";
import EditEmailTemplate from "../components/EmailTemplate/Edit";
import ViewAllEmailLogs from "../components/EmailLog/ViewAll";
import ViewAllFaqs from "../components/Faq/ViewAll";
import AddFaq from "../components/Faq/Add";
import EditFaq from "../components/Faq/Edit";
import ViewAllAdminRole from "../components/SubAdminRoles/ViewAll";
import AddAdminRole from "../components/SubAdminRoles/Add";
import EditAdminRole from "../components/SubAdminRoles/Edit";
import SeoPageAdd from "../components/SeoPage/Add";
import SeoPageViewAll from "../components/SeoPage/ViewAll";
import SeoPageEdit from "../components/SeoPage/Edit";

import ViewAllCustomer from "../components/Customers/ViewAll";
import AddCustomer from "../components/Customers/Add";
import EditCustomer from "../components/Customers/Edit";
import ViewCustomer from "../components/Customers/ViewOne";
import ChangePasswordCustomer from "../components/Customers/ChangePassword";

import ViewAllSubAdmin from "../components/SubAdmin/ViewAll";
import AddSubAdmin from "../components/SubAdmin/Add";
import EditSubAdmin from "../components/SubAdmin/Edit";
import ChangePasswordSubAdmin from "../components/SubAdmin/ChangePassword";

import ViewAllContactUs from "../components/ContactUs/ViewAll";

import ViewAllNewsletterSubscriptions from "../components/NewsletterSubscriptions/ViewAll";

import ViewAllMasterManagement from "../components/MasterManagement/ViewAll";
import AddMasterManagement from "../components/MasterManagement/Add";
import EditMasterManagement from "../components/MasterManagement/Edit";

import AddSectionOne from "../components/Home/SectionOne/Add";
import ViewAllSectionTwo from "../components/Home/SectionOne/ViewAll";
import EditSectionOne from "../components/Home/SectionOne/Edit";
import FooterSetting from "../components/Settings/FooterSetting";
import { components } from "react-select";
import ViewAllColors from "../components/Color/ViewAll";
import AddColor from "../components/Color/Add";
import EditColor from "../components/Color/Edit";
import ViewAllSizes from "../components/Size/ViewAll";
import AddSize from "../components/Size/Add";
import EditSize from "../components/Size/Edit";
import ViewAllBrands from "../components/Brand/ViewAll";
import AddBrand from "../components/Brand/Add";
import EditBrand from "../components/Brand/Edit";
import ViewAllFeatures from "../components/Feature/ViewAll";
import AddFeature from "../components/Feature/Add";
import EditFeature from "../components/Feature/Edit";
import ViewAllCategories from "../components/Category/ViewAll";
import AddCategory from "../components/Category/Add";
import EditCategory from "../components/Category/Edit";
import ViewAllCategoriesLevel2 from "../components/Category-Level-2/ViewAll";
import AddCategoryLevel2 from "../components/Category-Level-2/Add";
import EditCategoryLevel2 from "../components/Category-Level-2/Edit";
import ViewAllCategoriesLevel3 from "../components/Category-Level-3/ViewAll";
import AddCategoryLevel3 from "../components/Category-Level-3/Add";
import EditCategoryLevel3 from "../components/Category-Level-3/Edit";
import ViewAllProducts from "../components/Product/ViewAll";
import AddProduct from "../components/Product/Add";
import ProductDetails from "../components/Product/ProductDetails";
import EditProduct from "../components/Product/Edit";
import ViewAllOrders from "../components/Order/ViewAll";
import ViewOneOrders from "../components/Order/ViewOne";
import StockHistory from "../components/Product/StockHistory";
import ViewAllShippingCountries from "../components/ShippingCountry/ViewAll";
import AddShippingCountry from "../components/ShippingCountry/Add";
import EditShippingCountry from "../components/ShippingCountry/Edit";
import ViewAllStates from "../components/State/ViewAll";
import AddState from "../components/State/Add";
import EditState from "../components/State/Edit";
import ShippingPrice from "../components/ShippingCountry/ShippingPrice";
import ViewAllTaxes from "../components/TaxRate/ViewAll";
import AddTax from "../components/TaxRate/Add";
import EditTax from "../components/TaxRate/Edit";
import ViewAllCities from "../components/City/ViewAll";
import AddCity from "../components/City/Add";
import EditCity from "../components/City/Edit";
import ViewAllCoupon from "../components/Coupon/ViewAll";
import AddCoupon from "../components/Coupon/Add";
import EditCoupon from "../components/Coupon/Edit";
import CouponDetails from "../components/Coupon/CouponDetails";
import ProductSetting from "../components/ProductSetting/ProductSetting";
import AddTempProduct from "../components/Product/TempAdd";
import EditTempProduct from "../components/Product/TempEdit";

import AddCurrency from "../components/Currency/Add";
import EditCurrency from "../components/Currency/Edit";
import ViewAllCurrency from "../components/Currency/ViewAll";
import ViewAllTrending from "../components/Trendingsproducts/Viewall";
import ECommerceDashboard from "../pages/Dashboard/ECommerceDashboard";
import OrderDetailsReport from "../components/Reports/OrderDetails/ViewAll";
import OrderSummaryReport from "../components/Reports/OrderSummary/ViewAll";
import ProductQuantityReport from "../components/Reports/ProductQuantity/ViewAll";
import ProductVariantQuantityReport from "../components/Reports/ProductVariantQuantity/ViewAll";
import ProductStockLevelReport from "../components/Reports/ProductStockLevel/ViewAll";
import ProductStockMovementReport from "../components/Reports/ProductStockMovement/ViewAll";
import DiscountByOrderReport from "../components/Reports/DiscountByOrder/ViewAll";
import TotalDiscountAppliedReport from "../components/Reports/TotalDiscountApplied/ViewAll";
import CustomerAddressReport from "../components/Reports/CustomerAddress/ViewAll";
import SalesSummaryReport from "../components/Reports/SalesSummary/ViewAll";
import SalesByProductReport from "../components/Reports/SalesByProduct/ViewAll";
import ProductWaitingListReport from "../components/Reports/ProductWaitingList/ViewAll";
import ProductFeatureReport from "../components/Reports/ProductFeature/ViewAll";
import ShippingStatusReport from "../components/Reports/ShippingStatus/ViewAll";
import ShippingCostReport from "../components/Reports/ShippingCost/ViewAll";
import ReturnRequestReport from "../components/Reports/ReturnRequest/ViewAll";
import RefundIssuedReport from "../components/Reports/RefundIssued/ViewAll";
import OrderHistoryReport from "../components/Reports/OrderHistory/ViewAll";
import ViewAllRatings from "../components/Ratings/ViewAll";
import RatingDetails from "../components/Ratings/Details";
import ViewAllNotificationTemplate from "../components/NotificationTemplate/ViewAll";
import AddNotificationTemplate from "../components/NotificationTemplate/Add";
import EditNotificationTemplate from "../components/NotificationTemplate/Edit";
import NotificationsReport from "../components/Reports/Notifications/ViewAll";
import NotificationDetailsReport from "../components/Reports/NotificationDetails/ViewAll";

export const privateRoutes = [
  { path: "/", component: ECommerceDashboard, key: "1" },
  { path: "/setting", component: ViewAllSettings, key: "13" },
  { path: "/setting/prefix/:prefix", component: ViewPrefix, key: "13_1" },
  { path: "/setting/add", component: AddSetting, key: "13_1" },
  { path: "/setting/edit/:id", component: EditSetting, key: "13_1" },
  { path: "/cms", component: ViewAllCms, key: "12_1" },
  { path: "/cms/view/:id", component: ViewOneCms, key: "12_2" },
  { path: "/cms/add", component: AddCms, key: "12_1" },
  { path: "/cms/edit/:id", component: EditCms, key: "12_3" },
  { path: "/email-template", component: ViewAllEmailTemplate, key: "12_20" },
  /* { path: "/email-template/add", component: AddEmailTemplate, key: "12_20" }, */
  {
    path: "/email-template/edit/:id",
    component: EditEmailTemplate,
    key: "12_21",
  },
  { path: "/order/:id", component: ViewAllOrders, key: "7_1" },
  { path: "/order/view/:id", component: ViewOneOrders, key: "7_2" },
  { path: "/admin-roles", component: ViewAllAdminRole, key: "4_20" },
  { path: "/admin-role/add", component: AddAdminRole, key: "4_21" },
  { path: "/admin-role/edit/:id", component: EditAdminRole, key: "4_22" },
  { path: "/email-logs", component: ViewAllEmailLogs, key: "12_30" },
  { path: "/change-password", component: ChangePassword },
  { path: "/my-profile", component: Profile },

  { path: "/faq", component: ViewAllFaqs, key: "12_40" },
  { path: "/faq/add", component: AddFaq, key: "12_41" },
  { path: "/faq/edit/:id", component: EditFaq, key: "12_43" },

  { path: "/seo-pages", component: SeoPageViewAll, key: "12_10" },
  { path: "/seo-page/add", component: SeoPageAdd, key: "12_10" },
  { path: "/seo-page/edit/:id", component: SeoPageEdit, key: "12_11" },

  { path: "/customers", component: ViewAllCustomer, key: "2" },
  { path: "/customer/add", component: AddCustomer, key: "2_2" },
  { path: "/customer/edit/:id", component: EditCustomer, key: "2_5" },
  { path: "/customer/view/:id", component: ViewCustomer, key: "2_4" },
  {
    path: "/customer/change-password/:id",
    component: ChangePasswordCustomer,
    key: "2_8",
  },
  { path: "/languages", component: ViewAllLanguageSetting, key: "12_140" },
  { path: "/language/add", component: AddLanguageSetting, key: "12_140" },
  { path: "/sub-admins", component: ViewAllSubAdmin, key: "4_1" },
  { path: "/sub-admin/add", component: AddSubAdmin, key: "4_2" },
  { path: "/sub-admin/edit/:id", component: EditSubAdmin, key: "4_3" },
  {
    path: "/sub-admin/change-password/:id",
    component: ChangePasswordSubAdmin,
    key: "4_25",
  },

  { path: "/contact-us", component: ViewAllContactUs, key: "12_200" },

  {
    path: "/newsletter-subscribers",
    component: ViewAllNewsletterSubscriptions,
    key: "12_201",
  },

  {
    path: "/master/:type",
    component: ViewAllMasterManagement,
    key: "12_201",
  },
  {
    path: "/master/:type/add",
    component: AddMasterManagement,
    key: "12_201",
  },
  {
    path: "/master/:type/edit/:id",
    component: EditMasterManagement,
    key: "12_201",
  },

  {
    path: "/home/section-one/add",
    component: AddSectionOne,
    key: "12_201",
  },
  {
    path: "/home/section-one",
    component: ViewAllSectionTwo,
    key: "12_201",
  },
  {
    path: "/home/section-one/edit/:id",
    component: EditSectionOne,
    key: "12_201",
  },
  {
    path: "/home/footer-text",
    component: FooterSetting,
    key: "12_201",
  },
  {
    path: "/color",
    component: ViewAllColors,
  },
  {
    path: "/color/add",
    component: AddColor,
  },
  {
    path: "/color/edit/:id",
    component: EditColor,
  },
  {
    path: "/size",
    component: ViewAllSizes,
  },
  {
    path: "/size/add",
    component: AddSize,
  },
  {
    path: "/size/edit/:id",
    component: EditSize,
  },
  {
    path: "/brand",
    component: ViewAllBrands,
  },
  {
    path: "/brand/add",
    component: AddBrand,
  },
  {
    path: "/brand/edit/:id",
    component: EditBrand,
  },
  {
    path: "/feature",
    component: ViewAllFeatures,
  },
  {
    path: "/feature/add",
    component: AddFeature,
  },
  {
    path: "/feature/edit/:id",
    component: EditFeature,
  },
  {
    path: "/category",
    component: ViewAllCategories,
  },
  {
    path: "/category/add",
    component: AddCategory,
  },
  {
    path: "/category/edit/:id",
    component: EditCategory,
  },
  {
    path: "/category-level-2/:l1_id",
    component: ViewAllCategoriesLevel2,
  },
  {
    path: "/category-level-2/:l1_id/add",
    component: AddCategoryLevel2,
  },
  {
    path: "/category-level-2/:l1_id/edit/:id",
    component: EditCategoryLevel2,
  },
  {
    path: "/category-level-3/:l1_id/:l2_id",
    component: ViewAllCategoriesLevel3,
  },
  {
    path: "/category-level-3/:l1_id/:l2_id/add",
    component: AddCategoryLevel3,
  },
  {
    path: "/category-level-3/:l1_id/:l2_id/edit/:id",
    component: EditCategoryLevel3,
  },
  {
    path: "/product",
    component: ViewAllProducts,
  },
  {
    path: "/product/add",
    component: AddProduct,
  },
  {
    path: "/product-details/:id",
    component: ProductDetails,
  },
  {
    path: "/product/edit/:id",
    component: EditProduct,
  },
  {
    path: "/product/stock-history/:id",
    component: StockHistory,
  },
  {
    path: "/shipping-country",
    component: ViewAllShippingCountries,
  },
  {
    path: "/shipping-country/add",
    component: AddShippingCountry,
  },
  {
    path: "/shipping-country/edit/:id",
    component: EditShippingCountry,
  },
  {
    path: "/state/:countryId/",
    component: ViewAllStates,
  },
  {
    path: "/state/:countryId/add",
    component: AddState,
  },
  {
    path: "/state/:countryId/edit/:stateId",
    component: EditState,
  },

  {
    path: "/country/shipping-price/:countryId",
    component: ShippingPrice,
  },
  {
    path: "/tax/:countryId",
    component: ViewAllTaxes,
  },
  {
    path: "/tax/:countryId/add",
    component: AddTax,
  },
  {
    path: "/tax/:countryId/edit/:taxId",
    component: EditTax,
  },
  {
    path: "/country/:countryId/state/city/:stateId",
    component: ViewAllCities,
  },
  {
    path: "/country/:countryId/state/:stateId/city/add",
    component: AddCity,
  },
  {
    path: "/country/:countryId/state/:stateId/city/edit/:cityId",
    component: EditCity,
  },
  {
    path: "/coupon",
    component: ViewAllCoupon,
  },
  {
    path: "/coupon/add",
    component: AddCoupon,
  },
  {
    path: "/coupon/edit/:id",
    component: EditCoupon,
  },
  {
    path: "/coupon-details/:id",
    component: CouponDetails,
  },
  {
    path: "/trending-product-setting",
    component: ProductSetting,
  },
  {
    path: "/trending-products",
    component: ViewAllTrending,
    key: "8",
  },
  { path: "/currency/add", component: AddCurrency },
  { path: "/currency/edit/:id", component: EditCurrency },
  { path: "/currencies", component: ViewAllCurrency },
  { path: "/e-commerce-dashboard", component: ECommerceDashboard, key: "1" },
  { path: "/report/order-details", component: OrderDetailsReport },
  { path: "/report/order-summary", component: OrderSummaryReport },
  { path: "/report/product-quantity", component: ProductQuantityReport },
  {
    path: "/report/product-variant-quantity",
    component: ProductVariantQuantityReport,
  },
  {
    path: "/report/product-stock-level",
    component: ProductStockLevelReport,
  },
  {
    path: "/report/product-stock-movement",
    component: ProductStockMovementReport,
  },
  {
    path: "/report/discount-by-order",
    component: DiscountByOrderReport,
  },
  {
    path: "/report/total-discount-applied",
    component: TotalDiscountAppliedReport,
  },
  {
    path: "/report/customer-address",
    component: CustomerAddressReport,
  },
  {
    path: "/report/sales-summary",
    component: SalesSummaryReport,
  },
  {
    path: "/report/sales-by-product",
    component: SalesByProductReport,
  },
  {
    path: "/report/product-waiting-list",
    component: ProductWaitingListReport,
  },
  {
    path: "/report/product-feature",
    component: ProductFeatureReport,
  },
  {
    path: "/report/shipping-status",
    component: ShippingStatusReport,
  },
  {
    path: "/report/shipping-cost",
    component: ShippingCostReport,
  },
  {
    path: "/report/return-request",
    component: ReturnRequestReport,
  },
  {
    path: "/report/refund-issued",
    component: RefundIssuedReport,
  },
  {
    path: "/report/order-history",
    component: OrderHistoryReport,
  },
  {
    path: "/rating-review",
    component: ViewAllRatings,
  },
  {
    path: "/rating-review/details/:id",
    component: RatingDetails,
  },
  {
    path: "/notification-template",
    component: ViewAllNotificationTemplate,
  },
  /* {
    path: "/notification-template/add",
    component: AddNotificationTemplate,
  }, */
  {
    path: "/notification-template/edit/:id",
    component: EditNotificationTemplate,
  },
  {
    path: "/report/notifications",
    component: NotificationsReport,
  },
  {
    path: "/report/notification-details",
    component: NotificationDetailsReport,
  },
];
export const notPrivateRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
];
