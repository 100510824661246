import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Details = (props) => {
  const { id } = props.match.params;
  const { request, response } = useRequest();
  const [result, setResult] = useState();
  useEffect(() => {
    request("GET", `rating/${id}`);
  }, []);
  useEffect(() => {
    if (!response) return;
    setResult(response.result);
  }, [response]);
  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Review Rating"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/rating-review", name: "Review And Rating" },
        ]}
      />
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#kt_apps_contacts_view_tab_1"
                    >
                      <span className="nav-text">
                        Review Rating Information
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className="tab-pane active"
                  id="kt_apps_contacts_view_tab_1"
                  role="tabpanel"
                >
                  <div className="form-group row my-2">
                    <label className="col-4 col-form-label">Rating :</label>
                    <div className="col-8">
                      <span className="form-control-plaintext font-weight-bolder">
                        {Array.from({ length: result?.rating }).map(
                          (_, index) => (
                            <i
                              aria-hidden="true"
                              className="fa fa-star"
                              style={{ color: "orange" }}
                            ></i>
                          )
                        )}
                        {Array.from({ length: 5 - result?.rating }).map(
                          (_, index) => (
                            <i
                              aria-hidden="true"
                              className="fa fa-star"
                              style={{ color: "black" }}
                            ></i>
                          )
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row my-2">
                    <label className="col-4 col-form-label">Reviews :</label>
                    <div className="col-8">
                      <span className="form-control-plaintext font-weight-bolder">
                        {result?.review}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane"
                  id="kt_apps_contacts_view_tab_3"
                  role="tabpanel"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
