import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { imgArray } from "../../constant/constant";

import {
  Input,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  ReactSelectInput,
  PhoneNumber,
} from "../Form/Form";
import TooltipComponent from "../Tooltip/Tooltip";
import { productSettingTooltipText } from "../../util/helper";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();

  const { response, request } = useRequest();
  const { response: responseFetchSetting, request: requestFetchSetting } =
    useRequest();

  const history = useHistory();

  useEffect(() => {
    if (response) {
      toast.success("Setting has been added successfully.");
      history.push("/trending-product-setting");
    }
  }, [response]);

  useEffect(() => {
    requestFetchSetting("GET", "product/trending_product_settings");
  }, []);

  useEffect(() => {
    if (responseFetchSetting) {
      const { setting } = responseFetchSetting;
      reset({ ...setting });
      setValue("start_date", moment(setting.start_date).format("YYYY-MM-DD"));
      setValue("end_date", moment(setting.end_date).format("YYYY-MM-DD"));
    }
  }, [responseFetchSetting]);

  const onSubmit = (data) => {
    let {
      weight_for_sales_data,
      weight_for_product_page_views,
      start_date,
      end_date,
      n_products_to_display,
    } = data;

    let error = 0;

    if (error == 1) {
      return;
    }

    request("POST", "product/trending_product_settings", {
      weight_for_sales_data,
      weight_for_product_page_views,
      start_date,
      n_products_to_display,
      end_date,
    });
  };

  const InputFields = [
    {
      Component: Input,
      label: "Weight For Sales Data",
      type: "number",
      name: "weight_for_sales_data",
      registerFields: {
        required: true,
        min: 0,
      },
      colClass: "col-12",
      tooltip: {
        show: true,
        title: productSettingTooltipText[0],
      },
    },
    {
      Component: Input,
      label: "Weight For Product Page Views",
      type: "number",
      name: "weight_for_product_page_views",
      registerFields: {
        required: true,
        min: 0,
      },
      colClass: "col-12",
      tooltip: {
        show: true,
        title: productSettingTooltipText[1],
      },
    },
    {
      Component: Input,
      label: "Start Date",
      type: "date",
      isDate: true,
      name: "start_date",
      registerFields: {
        required: true,
      },
      clearErrors,
      control,
      colClass: "col-12",
      tooltip: {
        show: true,
        title: productSettingTooltipText[2],
      },
    },
    {
      Component: Input,
      label: "End Date",
      type: "date",
      isDate: true,
      name: "end_date",
      registerFields: {
        required: true,
      },
      clearErrors,
      control,
      colClass: "col-12",
      tooltip: {
        show: true,
        title: productSettingTooltipText[3],
      },
    },

    {
      Component: Input,
      label: "Number of Product to display",
      type: "number",
      name: "n_products_to_display",
      registerFields: {
        required: true,
        min: 0,
      },
      colClass: "col-12",
      tooltip: {
        show: true,
        title: productSettingTooltipText[4],
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Trending Product Setting"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/*  <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    /> */}
                    <div className="row">
                      {InputFields.map((Input, index) => (
                        <div className="input_tooltipBox col-lg-6">
                          <Input.Component
                            key={index}
                            {...Input}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                          />
                          {/* <TooltipComponent
                            customClass={"customClass"}
                            value={productSettingTooltipText[index]}
                          /> */}
                        </div>
                      ))}
                    </div>
                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
