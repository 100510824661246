import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
} from "../Form/Form";
import FormError from "../General/FormError";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, errors, register, required, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Add = (props) => {
  const ckEditorRef = useRef();
  const { countryId } = props.match.params;
  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      tax_type: "Fixed", // Set initial value here
    },
  });

  const { response, request } = useRequest();

  useEffect(() => {
    document.title = "Add-Tax - Single Vendor";
    //setValue("tax_type", "Fixed");
  }, []);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push(`/tax/${countryId}`);
    }
  }, [response]);

  const onSubmitNew = (data) => {
    //console.log("data:::::::::::::", data);
    const payload = {
      name: data[`name-en`],
      shipping_country_id: countryId,
      tax_type: data.tax_type,
      tax_value: data.tax_value,
    };

    const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      subData.push({
        language_id: id,
        name: data[`name-${code}`],
      });
    }

    payload.subData = subData;
    //console.log(payload);

    request("POST", "tax", payload);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        isRequired: true,
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  const TaxValueInputField = [
    {
      Component: Input,
      label: "Tax Value",
      name: "tax_value",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
  ];

  const onChangeRadio = (event) => {
    const value = event.target.value;
    //console.log(value);
    //setSelectedOption(value); // Update local state
    setValue("tax_type", value); // Update the form state
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add New Tax"
        links={[
          { to: "/", name: "Dashboard" },
          { to: `/shipping-country`, name: "Countries" },
          { to: `/tax/${countryId}`, name: "Taxes" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {languages.length > 0 &&
                    languages.map((lang, index) => (
                      <>
                        <SubInput
                          key={index}
                          index={index}
                          errors={errors}
                          register={register}
                          InputFields={InputFields}
                          code={lang.code}
                        />
                      </>
                    ))}
                  <div className="row">
                    <div class="col-lg-6">
                      <label>Tax Type</label>
                      <span class="text-danger"> </span>
                      <div class="discount-input">
                        <div class="form-group custom_radio">
                          <input
                            type="radio"
                            id="Fixed"
                            value="Fixed"
                            // checked={getValues("tax_type") == "Fixed"}
                            {...register("tax_type")}
                            onChange={onChangeRadio}
                          />
                          <label for="Fixed" class="m-0">
                            Fixed
                          </label>
                        </div>
                        <div class="form-group custom_radio">
                          <input
                            type="radio"
                            id="Percentage"
                            value="Percentage"
                            // checked={getValues("tax_type") == "Percentage"}
                            {...register("tax_type")}
                            onChange={onChangeRadio}
                          />
                          <label for="Percentage" class="m-0">
                            Percentage
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {TaxValueInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <FormError errors={errors} />

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Add"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Add;
