import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Chart from "../../components/Chart/Chart";
import useRequest from "../../hooks/useRequest";
import {
  DashboardCategoryIconSvg,
  DashboardCustomerSvg,
  DashboardShippingCountriesSvg,
  DashboardUserIconSvg,
  OrderCancelSvg,
  OrderConfirmSvg,
  OrderDeliveredSvg,
  OrderDispatchedSvg,
  OrderInTransitSvg,
  OrderPendingActionSvg,
  OrderReturnSvg,
  OrderShippedSvg,
  OrderSvg,
  SaleSvg,
  UnitsSoldSvg,
} from "../../util/Svg";
import ReactApexChart from "react-apexcharts";

const ECommerceDashboard = () => {
  const [statics, setStatics] = useState();
  const [userStatics, setUserStatics] = useState();
  const [topProductQunatitytChartData, setTopProductQunatitytChartData] =
    useState({
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
    });
  const [topProductSellChartData, setTopProductSellChartData] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
    },
  });
  const [topCustomersChartData, setTopCustomersChartData] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const { request, response } = useRequest();
  const { request: userStatisticsReq, response: userStatisticsRes } =
    useRequest();

  useEffect(() => {
    request("GET", "product/statistics");
    userStatisticsReq("GET", "user/latest");
    document.title = "Dashboard - Single Vendor";
  }, []);

  useEffect(() => {
    if (userStatisticsRes) {
      const { totalStudents } = userStatisticsRes;
      setUserStatics(totalStudents);
      // setTotalVendors(totalVendors);
      // setTotalProducts(totalProducts);
    }
  }, [userStatisticsRes]);

  useEffect(() => {
    if (response) {
      setStatics(response.data);
      const topProductQunatities = response.data["Top5ProductQuantitySell"].map(
        (p) => p.quantity * 1
      );
      const topProductQunatitiesName = response.data[
        "Top5ProductQuantitySell"
      ].map((p) => p.name);
      setTopProductQunatitytChartData({
        series: [
          {
            data: topProductQunatities, // Adjust to your actual API response
          },
        ],
        options: {
          ...topProductQunatitytChartData.options, // Spread the previous options
          xaxis: {
            categories: topProductQunatitiesName, // Set categories dynamically from API
          },
        },
      });

      const topProductSellPrice = response.data["Top5ProductSell"].map(
        (p) => p.total_price * 1
      );
      const topProductSellName = response.data["Top5ProductSell"].map(
        (p) => p.name
      );
      setTopProductSellChartData({
        series: [
          {
            data: topProductSellPrice, // Adjust to your actual API response
          },
        ],
        options: {
          ...topProductSellChartData.options, // Spread the previous options
          xaxis: {
            categories: topProductSellName, // Set categories dynamically from API
          },
        },
      });
      const topCustomerOrderPrice = response.data["Top5Customers"].map(
        (p) => p.total_price * 1
      );
      const topCustomerName = response.data["Top5Customers"].map((p) => p.name);
      setTopCustomersChartData({
        series: [
          {
            data: topCustomerOrderPrice, // Adjust to your actual API response
          },
        ],
        options: {
          ...topCustomersChartData.options, // Spread the previous options
          xaxis: {
            categories: topCustomerName, // Set categories dynamically from API
          },
        },
      });
    }
  }, [response]);

  if (!statics || !userStatics) return <></>;

  return (
    <>
      <div
        className="content  d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div
          className="subheader py-2 py-lg-4  subheader-solid "
          id="kt_subheader"
        >
          <div className=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-1">
              <div className="d-flex align-items-baseline flex-wrap mr-5">
                <h5 className="text-dark font-weight-bold my-1 mr-5">
                  Dashboard{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column-fluid">
          <div className=" container ">
            <div className="row">
              <div className="col-xl-4">
                <Link
                  to={"/customers"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <DashboardCustomerSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {userStatics}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Customers
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <SaleSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      USD {statics.total_sales_sum}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Sales
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <SaleSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      USD {statics.total_sales_daily_sum}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Average Daily Sales
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.total_orders_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Orders
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      USD {Math.round(statics.average_order_value_sum)}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Average Order Value
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <UnitsSoldSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.total_units_sold_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Units Sold
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <UnitsSoldSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.avg_quantity_per_order}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Average Units Per Order
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/product"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <UnitsSoldSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.total_products_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Products
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/Returned"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <UnitsSoldSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      USD {statics.total_refunds}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Refunds
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/delivered"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <UnitsSoldSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.average_time_to_deliver}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Average Time To Deliver
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/category"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <DashboardCategoryIconSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.categories_count.category_level_1_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Categories 1
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/category"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <DashboardCategoryIconSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.categories_count.category_level_2_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Categories 2
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/category"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <DashboardCategoryIconSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.categories_count.category_level_3_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Categories 3
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <DashboardUserIconSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.total_user_purchased}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Users Purchased
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <DashboardShippingCountriesSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.total_countries_place_order}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Total Countries Placed Order
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_pending_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Pending Orders
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderPendingActionSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_pending_action_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Pending Actions
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderDispatchedSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_dispatched_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Dispatched
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderShippedSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_shipped_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Shipped
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderInTransitSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_in_transit_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      In Transit
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderDeliveredSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_delivered_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Delivered Orders
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderCancelSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_cancelled_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Cancelled Orders
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderReturnSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_return_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Returned Orders
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-4">
                <Link
                  to={"/order/all"}
                  className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                      <OrderConfirmSvg />
                    </span>

                    <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                      {statics.order_status_count.order_confirmed_count}
                    </div>
                    <div className="font-weight-bold text-inverse-danger font-size-sm">
                      Order Confirmed
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div class="card card-custom gutter-b gutter-b">
              <div class="card-header h-auto border-0">
                <div class="card-title py-5">
                  <h3 class="card-label">
                    <span class="d-block text-dark font-weight-bolder">
                      Top 5 Products{" "}
                    </span>
                    <span class="subSpan">(By Quantity)</span>
                  </h3>
                </div>
              </div>

              <div class="card-body pt-0" style={{ position: "relative" }}>
                <ReactApexChart
                  options={topProductQunatitytChartData.options}
                  series={topProductQunatitytChartData.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>

            <div class="card card-custom gutter-b gutter-b">
              <div class="card-header h-auto border-0">
                <div class="card-title py-5">
                  <h3 class="card-label">
                    <span class="d-block text-dark font-weight-bolder">
                      Top 5 Products{" "}
                    </span>
                    <span class="subSpan">(By Sells) USD</span>
                  </h3>
                </div>
              </div>

              <div class="card-body pt-0" style={{ position: "relative" }}>
                <ReactApexChart
                  options={topProductSellChartData.options}
                  series={topProductSellChartData.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>

            <div class="card card-custom gutter-b gutter-b">
              <div class="card-header h-auto border-0">
                <div class="card-title py-5">
                  <h3 class="card-label">
                    <span class="d-block text-dark font-weight-bolder">
                      Top 5 Customers{" "}
                    </span>
                    <span class="subSpan">(By Total Orders) USD</span>
                  </h3>
                </div>
              </div>

              <div class="card-body pt-0" style={{ position: "relative" }}>
                <ReactApexChart
                  options={topCustomersChartData.options}
                  series={topCustomersChartData.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ECommerceDashboard;
