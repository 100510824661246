import { useState, useEffect } from "react";
import "../product.css";

const TempImageUploader = ({
  images,
  setImages,
  coverImage,
  setCoverImage,
}) => {
  const maxLength = 20;

  // Cleanup URL.createObjectURL links on component unmount
  useEffect(() => {
    return () => {
      images.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [images]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    processFiles(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    processFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const processFiles = (files) => {
    const newImages = [];

    files.forEach((file) => {
      if (!file.type.match("image.*")) return; // Check if file is an image
      if (newImages.length >= maxLength) return; // Limit to maxLength

      // Add preview URL to each image object for display
      file.preview = URL.createObjectURL(file);
      newImages.push(file);
    });

    // Set the first image as the cover if no cover is set
    if (!coverImage && newImages.length > 0) setCoverImage(newImages[0]);

    setImages((prevImages) => {
      const combinedImages = [...prevImages, ...newImages];
      return combinedImages.slice(0, maxLength); // Ensure we do not exceed maxLength
    });
  };

  const handleImageRemove = (fileName) => {
    let newCoverImage = coverImage;
    if (coverImage && coverImage.name === fileName) newCoverImage = undefined;

    const newImages = images.filter((file) => file.name !== fileName);
    setImages(newImages);

    if (!newCoverImage && newImages.length) {
      newCoverImage = newImages[0];
    }

    setCoverImage(newCoverImage);
  };

  const makeCoverImage = (file) => {
    setCoverImage(file);
  };

  return (
    <div
      className="upload__box"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="upload__btn-box">
        <label className="upload__btn">
          <p>Drag and drop or Upload from your computer</p>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="upload__inputfile"
            data-max_length={maxLength}
          />
        </label>
      </div>
      <div className="upload__img-wrap">
        {images.map((file, index) => (
          <div key={index} className="upload__img-box">
            <div
              style={{ backgroundImage: `url(${file.preview})` }}
              data-file={file.name}
              className="img-bg"
            >
              {coverImage === file && (
                <h3 className="cover_image_add image_coverdesgin">
                  Cover Photo
                </h3>
              )}

              <div
                data-toggle="tooltip"
                data-placement="top"
                title="Make cover photo"
                className="upload__img-icon upload__img-cover"
                onClick={() => makeCoverImage(file)}
              ></div>
              <div
                className="upload__img-icon upload__img-close"
                onClick={() => handleImageRemove(file.name)}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TempImageUploader;

/* import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone"; // Import from react-dropzone
import "../product.css";

const TempImageUploader = ({
  images,
  setImages,
  coverImage,
  setCoverImage,
}) => {
  const maxLength = 20;

  // Cleanup URL.createObjectURL links on component unmount
  useEffect(() => {
    return () => {
      images.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [images]);

  // Handling the file drop using react-dropzone's useDropzone hook
  const onDrop = (acceptedFiles) => {
    processFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Accept only images
    multiple: true,
    maxFiles: maxLength, // Limit number of files to maxLength
  });

  const processFiles = (files) => {
    const newImages = [];

    files.forEach((file) => {
      // Add preview URL to each image object for display
      file.preview = URL.createObjectURL(file);
      newImages.push(file);
    });

    // Set the first image as the cover if no cover is set
    if (!coverImage && newImages.length > 0) setCoverImage(newImages[0]);

    setImages((prevImages) => {
      const combinedImages = [...prevImages, ...newImages];
      return combinedImages.slice(0, maxLength); // Ensure we do not exceed maxLength
    });
  };

  const handleImageRemove = (fileName) => {
    let newCoverImage = coverImage;
    if (coverImage && coverImage.name === fileName) newCoverImage = undefined;

    const newImages = images.filter((file) => file.name !== fileName);
    setImages(newImages);

    if (!newCoverImage && newImages.length) {
      newCoverImage = newImages[0];
    }

    setCoverImage(newCoverImage);
  };

  const makeCoverImage = (file) => {
    setCoverImage(file);
  };

  return (
    <div className="upload__box" {...getRootProps()}>
      <div className="upload__btn-box">
        <label className="upload__btn">
          <p>temp Drag and drop or Upload from your computer</p>
          <input
            {...getInputProps()}
            className="upload__inputfile"
            data-max_length={maxLength}
          />
        </label>
      </div>

      <div className="upload__img-wrap">
        {images.map((file, index) => (
          <div key={index} className="upload__img-box">
            <div
              style={{ backgroundImage: `url(${file.preview})` }}
              data-file={file.name}
              className="img-bg"
            >
              {coverImage === file && (
                <h3 className="cover_image_add image_coverdesgin">
                  Cover Photo
                </h3>
              )}

              <div
                data-toggle="tooltip"
                data-placement="top"
                title="Make cover photo"
                className="upload__img-icon upload__img-cover"
                onClick={(e) => {
                  e.stopPropagation();
                  makeCoverImage(file);
                }}
              ></div>
              <div
                className="upload__img-icon upload__img-close"
                onClick={(e) => {
                  e.stopPropagation();
                  handleImageRemove(file.name);
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TempImageUploader;
 */
