import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Pagination from "../../Pagination/Pagination";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Table from "../../Table/Table";
import { SearchInput, SearchSubmitButton, SelectInput } from "../../Form/Form";
import { addOneToDate } from "../../../util/fn";
import { BASEURL } from "../../../constant/api";
import { stockMovementType } from "../../../util/helper";
import Moment from "moment";

const api_path = "report/product-waiting-list";

const OBJ_TABLE = {
  "Request ID": "request_id",
  "Customer ID": "customer_id",
  "Customer Name": "customer_name",
  "Email Address": "email",
  "product ID": "product_id",
  "product Name": "product_name",
  color: "color_name",
  size: "size_name",
  "category 1": "categoryL1Name",
  "category 2": "categoryL2Name",
  "category 3": "categoryL3Name",
  "Date Of Request": "date_of_request",
  "Current Stock Level": "current_stock_level",
  "Last Stock Update Date": "last_stock_update_date",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  request_id = "",
  customer_id = "",
  customer_name = "",
  email_address = "",
  product = "",
  color = "",
  size = "",
  current_stock_level = "",
  parent_category = "",
  category_level_2 = "",
  category_level_3 = "",
  dateFrom = "1970-01-01",
  dateTo,
  export_excel = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "created_at";
    }
    if (sortBy == "category") {
      sortBy = "categoryHierarchy";
    }

    if (sortBy == "status") sortBy = "is_active";
  } else {
    sortBy = "created_at";
  }

  order = order.length > 0 ? order : "desc";
  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${api_path}?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&request_id=${request_id}&customer_id=${customer_id}&customer_name=${customer_name}&email_address=${email_address}&product=${product}&color=${color}&size=${size}&current_stock_level=${current_stock_level}&parent_category=${parent_category}&category_level_2=${category_level_2}&category_level_3=${category_level_3}&dateFrom=${dateFrom}&dateTo=${dateTo}&export_excel=${export_excel}`;
};

const ViewAll = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState([]);
  const [categoryL3Input, setCategoryL3Input] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();

  const { request: colorRequest, response: colorResponse } = useRequest();
  const { request: sizeRequest, response: sizeResponse } = useRequest();

  useEffect(() => {
    colorRequest("GET", "color/active");
    sizeRequest("GET", "size/active");
  }, []);

  useEffect(() => {
    if (!colorResponse) return;
    let colorsList = colorResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColors(colorsList);
  }, [colorResponse]);

  useEffect(() => {
    if (!sizeResponse) return;
    let sizesList = sizeResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizes(sizesList);
  }, [sizeResponse]);

  useEffect(() => {
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
  }, []);

  useEffect(() => {
    if (!parentCategoryResponse) return;
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  const changeParentCategory = (value) => {
    //getCategoryLevel2()
    //console.log(value);
    setCategoryL2Input([]);
    setCategoryL3Input([]);
    setValue("category_level_2", "");
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL2 = (value) => {
    setCategoryL3Input([]);
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL2Input(categoryLevel2Response.categories);
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL3Input(categoryLevel3Response.categories);
  }, [categoryLevel3Response]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Report Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      //console.log("res", response.products);
      setData(response.data);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setData([]);
    //product which is enter on search input
    const {
      request_id,
      customer_id,
      customer_name,
      email_address,
      product,
      color,
      size,
      current_stock_level,
      parent_category,
      category_level_2,
      category_level_3,
      dateFrom,
      dateTo,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        request_id,
        customer_id,
        customer_name,
        email_address,
        product,
        color,
        size,
        current_stock_level,
        parent_category,
        category_level_2,
        category_level_3,
        dateFrom,
        dateTo
      )
    );
  };

  const onSearchHandler = (data) => {
    const {
      request_id,
      customer_id,
      customer_name,
      email_address,
      product,
      color,
      size,
      current_stock_level,
      parent_category,
      category_level_2,
      category_level_3,
      dateFrom,
      dateTo,
    } = getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        request_id,
        customer_id,
        customer_name,
        email_address,
        product,
        color,
        size,
        current_stock_level,
        parent_category,
        category_level_2,
        category_level_3,
        dateFrom,
        dateTo
      )
    );
    setData([]);
    setPage(1);
  };

  const onResetHandler = (e) => {
    setData([]);
    e.preventDefault();
    resetField("request_id");
    resetField("customer_id");
    resetField("customer_name");
    resetField("email_address");
    resetField("product");
    resetField("color");
    resetField("size");
    resetField("current_stock_level");
    resetField("parent_category");
    resetField("category_level_2");
    resetField("category_level_3");
    resetField("dateFrom");
    resetField("dateTo");

    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    setData([]);
    const {
      request_id,
      customer_id,
      customer_name,
      email_address,
      product,
      color,
      size,
      current_stock_level,
      parent_category,
      category_level_2,
      category_level_3,
      dateFrom,
      dateTo,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        request_id,
        customer_id,
        customer_name,
        email_address,
        product,
        color,
        size,
        current_stock_level,
        parent_category,
        category_level_2,
        category_level_3,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  /* const sortingHandler = (sortBy) => {
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      discount_price,
      dateFrom,
      dateTo,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  }; */

  const downloadExcelSearchQuery = () => {
    const {
      request_id,
      customer_id,
      customer_name,
      email_address,
      product,
      color,
      size,
      current_stock_level,
      parent_category,
      category_level_2,
      category_level_3,
      dateFrom,
      dateTo,
    } = getValues();

    const path = searchQueryHandler(
      "",
      "",
      currentSort.sortBy,
      currentSort.order,
      request_id,
      customer_id,
      customer_name,
      email_address,
      product,
      color,
      size,
      current_stock_level,
      parent_category,
      category_level_2,
      category_level_3,
      dateFrom,
      dateTo,
      1
    );

    return path.split("?")[1];
  };

  //search input field
  const InputFields = [
    {
      label: "Request Id",
      name: "request_id",
      required: false,
    },
    {
      label: "Customer Id",
      name: "customer_id",
      required: false,
    },
    {
      label: "Customer Name",
      name: "customer_name",
      required: false,
    },
    {
      label: "Email Address",
      name: "email_address",
      required: false,
    },

    {
      label: "Product Name",
      name: "product",
      required: false,
    },
    {
      isSelectInput: true,
      label: "Color",
      name: "color",
      required: false,
      children: (
        <>
          <option value={""}>All</option>,
          {colors.map((color) => (
            <option value={color.value}>{color.label}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Size",
      name: "size",
      required: false,
      children: (
        <>
          <option value={""}>All</option>,
          {sizes.map((size) => (
            <option value={size.value}>{size.label}</option>
          ))}
        </>
      ),
    },

    {
      label: "Current Stock Level",
      name: "current_stock_level",
      required: false,
    },

    {
      isSelectInput: true,
      label: "Parent Category",
      name: "parent_category",
      required: false,
      onChange: changeParentCategory,
      children: (
        <>
          <option value={""}>All</option>,
          {parentCategoryInput.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 2",
      name: "category_level_2",
      required: false,
      onChange: changeCategoryL2,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 3",
      name: "category_level_3",
      required: false,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      label: "Request Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Request Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  //console.log(products, "products");

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Product Waiting List"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <a
                      href={`${
                        BASEURL.PORT
                      }/${api_path}?${downloadExcelSearchQuery()}`}
                      className="btn btn-primary mr-2"
                    >
                      Export
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      //sortingHandler={sortingHandler}
                      mainData={data}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      onlyDate={{
                        date_of_request: "date",
                        last_stock_update_date: "date",
                      }}
                      dontShowSort={[
                        "Request ID",
                        "Customer ID",
                        "Customer Name",
                        "Email Address",
                        "product ID",
                        "product Name",
                        "color",
                        "size",
                        "category 1",
                        "category 2",
                        "category 3",
                        "Date Of Request",
                        "Current Stock Level",
                        "Last Stock Update Date",
                      ]}
                      renderAs={{
                        customer_id: (data) =>
                          data ? <div> #{data} </div> : <div>-</div>,
                        request_id: (data) =>
                          data ? <div> #{data} </div> : <div>-</div>,
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={data.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
