import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Pagination from "../../Pagination/Pagination";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Table from "../../Table/Table";
import { SearchInput, SearchSubmitButton, SelectInput } from "../../Form/Form";
import { addOneToDate } from "../../../util/fn";
import { BASEURL } from "../../../constant/api";

const api_path = "report/product-stock-level";

const OBJ_TABLE = {
  "Product ID": "id",
  "Product Name": "name",
  Color: "color",
  Size: "size",
  "Category 1": "categoryL1Name",
  "Category 2": "categoryL2Name",
  "Category 3": "categoryL3Name",
  "Current Stock Level": "stock_qty",
  "Discount Price": "discount_price",
  "Last Order Date": "last_order",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  product = "",

  parent_category = "",
  category_level_2 = "",
  category_level_3 = "",
  product_id = "",
  stock_qty = "",
  discount_price = "",
  dateFrom = "1970-01-01",
  dateTo,
  color = "",
  size = "",
  export_excel = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "created_at";
    }
    if (sortBy == "category") {
      sortBy = "categoryHierarchy";
    }

    if (sortBy == "status") sortBy = "is_active";
  } else {
    sortBy = "created_at";
  }

  order = order.length > 0 ? order : "desc";
  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${api_path}?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&product=${product}&parent_category=${parent_category}&category_level_2=${category_level_2}&category_level_3=${category_level_3}&product_id=${product_id}&stock_qty=${stock_qty}&discount_price=${discount_price}&dateFrom=${dateFrom}&dateTo=${dateTo}&color=${color}&size=${size}&export_excel=${export_excel}`;
};

const ViewAll = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState([]);
  const [categoryL3Input, setCategoryL3Input] = useState([]);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm();

  const { request, response } = useRequest();

  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();

  const { request: colorRequest, response: colorResponse } = useRequest();
  const { request: sizeRequest, response: sizeResponse } = useRequest();

  useEffect(() => {
    colorRequest("GET", "color/active");
    sizeRequest("GET", "size/active");
  }, []);

  useEffect(() => {
    if (!colorResponse) return;
    let colorsList = colorResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColors(colorsList);
  }, [colorResponse]);

  useEffect(() => {
    if (!sizeResponse) return;
    let sizesList = sizeResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizes(sizesList);
  }, [sizeResponse]);

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Product- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      //console.log("res", response.products);
      setProducts(response.products);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
  }, []);

  useEffect(() => {
    if (!parentCategoryResponse) return;
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  const changeParentCategory = (value) => {
    //getCategoryLevel2()
    //console.log(value);
    setCategoryL2Input([]);
    setCategoryL3Input([]);
    setValue("category_level_2", "");
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL2 = (value) => {
    setCategoryL3Input([]);
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL3 = (value) => {
    const inputVarientList = [];

    if (!value) return;
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL2Input(categoryLevel2Response.categories);
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL3Input(categoryLevel3Response.categories);
  }, [categoryLevel3Response]);

  const fetchMoreData = ({ selected }) => {
    setProducts([]);
    //product which is enter on search input
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      stock_qty,
      discount_price,
      dateFrom,
      dateTo,
      color,
      size,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        product,
        parent_category,
        category_level_2,
        category_level_3,
        product_id,
        stock_qty,
        discount_price,
        dateFrom,
        dateTo,
        color,
        size
      )
    );
  };

  const onSearchHandler = (data) => {
    setProducts([]);
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      stock_qty,
      discount_price,
      dateFrom,
      dateTo,
      color,
      size,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        product,
        parent_category,
        category_level_2,
        category_level_3,
        product_id,
        stock_qty,
        discount_price,
        dateFrom,
        dateTo,
        color,
        size
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    setProducts([]);
    e.preventDefault();
    resetField("product");
    resetField("parent_category");
    resetField("category_level_2");
    resetField("category_level_3");
    resetField("product_id");
    resetField("stock_qty");
    resetField("discount_price");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("color");
    resetField("size");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    setProducts([]);
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      stock_qty,
      discount_price,
      dateFrom,
      dateTo,
      color,
      size,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        product,
        parent_category,
        category_level_2,
        category_level_3,
        product_id,
        stock_qty,
        discount_price,
        dateFrom,
        dateTo,
        color,
        size
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  /* const sortingHandler = (sortBy) => {
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      discount_price,
      dateFrom,
      dateTo,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  }; */

  const downloadExcelSearchQuery = () => {
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      stock_qty,
      discount_price,
      dateFrom,
      dateTo,
      color,
      size,
    } = getValues();

    const path = searchQueryHandler(
      "",
      "",
      currentSort.sortBy,
      currentSort.order,
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      stock_qty,
      discount_price,
      dateFrom,
      dateTo,
      color,
      size,
      1
    );

    return path.split("?")[1];
  };

  //search input field
  const InputFields = [
    {
      label: "Product Id",
      name: "product_id",
      required: false,
    },
    {
      label: "Product Name",
      name: "product",
      required: false,
    },
    {
      isSelectInput: true,
      label: "Color",
      name: "color",
      required: false,
      children: (
        <>
          <option value={""}>All</option>,
          {colors.map((color) => (
            <option value={color.value}>{color.label}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Size",
      name: "size",
      required: false,
      children: (
        <>
          <option value={""}>All</option>,
          {sizes.map((size) => (
            <option value={size.value}>{size.label}</option>
          ))}
        </>
      ),
    },
    {
      label: "Available Quantity",
      name: "stock_qty",
      required: false,
    },
    {
      label: "Discount Price",
      name: "discount_price",
      required: false,
    },
    {
      isSelectInput: true,
      label: "Parent Category",
      name: "parent_category",
      required: false,
      onChange: changeParentCategory,
      children: (
        <>
          <option value={""}>All</option>,
          {parentCategoryInput.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 2",
      name: "category_level_2",
      required: false,
      onChange: changeCategoryL2,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 3",
      name: "category_level_3",
      required: false,
      onChange: changeCategoryL3,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  //console.log(products, "products");

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Product Stock Level"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <a
                      href={`${
                        BASEURL.PORT
                      }/${api_path}?${downloadExcelSearchQuery()}`}
                      className="btn btn-primary mr-2"
                    >
                      Export
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      //sortingHandler={sortingHandler}
                      mainData={products}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      onlyDate={{
                        last_order: "date",
                      }}
                      dontShowSort={[
                        "Product ID",
                        "Product Name",
                        "Category 1",
                        "Category 2",
                        "Category 3",
                        "Last Order Date",
                        "Discount Price",
                        "Color",
                        "Size",
                        "Current Stock Level",
                      ]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={products.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
