import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  CKEditorInput,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  RenderMultiLangInputFields,
} from "../Form/Form";
import { API } from "../../constant/api";
import { useSelector } from "react-redux";
import FormError from "../General/FormError";
import { getValueInLanguage } from "../../util/helper";

const SubTab = ({ id, name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#${id}_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({
  id,
  index,
  errors,
  register,
  required,
  InputFields,
  code,
}) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`${id}_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const { id: emailTemplateId } = props.match.params;
  const { languages } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const ckEditorRefs = useRef([]);

  const [emailConstants, setEmailConstants] = useState([]);
  const [langDataIds, setLangDataIds] = useState({});

  const { response, request } = useRequest();
  const [isCkContent, setIsCkContent] = useState(false);

  const { response: responseFetchTemplate, request: requestFetchTemplate } =
    useRequest();

  const history = useHistory();

  useEffect(() => {
    register("body", { required: true });
    requestFetchTemplate("GET", `email-template/${emailTemplateId}`);
    document.title = "Edit Email Template - Single Vendor";
  }, []);

  /* useEffect(() => {
    if (responseFetchTemplate) {
      const { body, options, name, subject } =
        responseFetchTemplate.emailTemplate;
      reset({ body, name, subject });
      setEmailConstants(options);
      setIsCkContent(true);
    }
  }, [responseFetchTemplate]); */

  useEffect(() => {
    if (responseFetchTemplate) {
      const { emailTemplate: sectionData } = responseFetchTemplate;

      if (!sectionData) {
        history.push("/email-template");
        return;
      }

      let languageDatas = sectionData.languageData;
      const resetObj = {};
      const langDataIdsObj = {};
      resetObj.name = sectionData.name;
      setEmailConstants(sectionData.options);

      for (let i = 0; i < languages.length; i++) {
        const id = languages[i].id;
        const code = languages[i].code;
        const currValue = getValueInLanguage(languageDatas, id);
        resetObj[`subject-${code}`] = currValue?.subject || "";
        resetObj[`body-${code}`] = currValue?.body || "";
        langDataIdsObj[code] = currValue?.id;
      }
      setLangDataIds(langDataIdsObj);
      reset(resetObj);
      setIsCkContent(true);
    }
  }, [responseFetchTemplate]);

  useEffect(() => {
    if (response) {
      toast.success("Email Template has been updated successfully.");
      history.push("/email-template");
    }
  }, [response]);

  const insertConstantHandler = () => {
    const constant = getValues("constant");
    console.log(constant, "constant");
    if (constant.length == 0) {
      return;
    }
    console.log(constant, "afer");

    // ckEditorRef.current.model.change((writer) => {
    //   writer.insertText(
    //     `{${constant}}`,
    //     ckEditorRef.current.model.document.selection.getFirstPosition()
    //   );
    // });
    //console.log("rr", ckEditorRef.current.editor);
    //ckEditorRef.current.editor.insertText(`{${constant}}`);
    //console.log("ss", ckEditorRefs.current);
    ckEditorRefs.current.forEach((temp) => {
      //console.log(temp);
      if (temp && temp.editor) temp.editor.insertText(`{${constant}}`);
    });
  };

  const handleonInstanceReady = (editor) => {
    ckEditorRefs.current.push(editor);
  };

  /* const onSubmit = (data) => {
    const { body, name, subject } = data;
    if (body.length === 0) {
      setError("body", {
        type: "manual",
      });
      return;
    }
    request("PUT", "email-template", {
      body,
      // name,
      subject,
      id: emailTemplateId,
    });
  }; */

  const onSubmit = (data) => {
    //const { body, name, subject } = data;
    /* if (body.length === 0) {
      setError("body", {
        type: "manual",
      });
      return;
    } */

    const payload = {
      id: emailTemplateId,
      subject: data[`subject-en`],
      body: data["body-en"],
    };

    const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const lang_id = languages[i].id;
      const code = languages[i].code;
      subData.push({
        subject: data[`subject-${code}`],
        body: data[`body-${code}`],
        id: langDataIds[code],
        lang_id,
      });
    }

    payload.subData = subData;
    console.log("pp", payload);
    request("PUT", "email-template", payload);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          disabled: true,
        },
      },
      {
        Component: SelectInput,
        label: "Constants",
        name: "constant",
        registerFields: {},
        children: (
          <>
            <option value="">{"Select Constant"}</option>
            {emailConstants.length > 0 &&
              emailConstants.map((constant, index) => (
                <option key={index} value={constant}>
                  {constant}
                </option>
              ))}
          </>
        ),
        moreData: (
          <button
            onClick={insertConstantHandler}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
          >
            Insert
          </button>
        ),
      },
    ],
  ];

  const languageInput = [
    [
      {
        Component: Input,
        label: "Subject",
        type: "text",
        name: "subject",
        registerFields: {
          required: true,
        },
        isRequired: true,
      },
    ],
    [
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Email Body",
        name: "body",
        registerFields: {
          required: true,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Email Body is required",
        },

        handleonInstanceReady: handleonInstanceReady,
        getValues,
        setValue,
        trigger,
        clearErrors,
        isEdit: true,
        setError,
        isCkContent,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Email Template"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/email-template", name: "Back To Email Template" },
        ]}
      />
      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Update Email Template
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <div className="card-header-tabs-line">
                      <div className="card-toolbar">
                        <ul
                          className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                          role="tablist"
                        >
                          {languages.length > 0 &&
                            languages.map((lang, index) => (
                              <SubTab
                                id="productInformation"
                                key={index}
                                name={lang.name}
                                index={index}
                                image={lang?.image}
                              />
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="tab-content px-0">
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <>
                              <SubInput
                                id="productInformation"
                                key={index}
                                index={index}
                                errors={errors}
                                register={register}
                                InputFields={languageInput}
                                code={lang.code}
                              />
                            </>
                          ))}
                      </div>
                    </div>

                    <FormError errors={errors} />

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
